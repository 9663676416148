.root {
  padding: 0 20px 64px;
  background: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 1136px;
  margin: 0 auto;
}

.quadriptych {
  align-self: center;
  width: 100%;
  margin-bottom: 16px;
}

.body {
  display: grid;
  grid-template-rows: min-content min-content min-content;
  align-self: center;
  gap: 12px;
  width: 100%;
  line-height: 1.5;
  letter-spacing: -0.025em;
}

.body h2 {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 600;
  color: var(--color-grey-1000);
}

.body p {
  font-size: 1rem;
  color: var(--color-grey-600);
}

.link {
  font-size: 1rem;
}

@media (min-width: 561px) {
  .root {
    padding: 0 32px 100px;
  }

  .container {
    flex-direction: row-reverse;
  }

  .quadriptych {
    margin-left: 32px;
    margin-bottom: 0;
  }

  .body {
    gap: 16px;
  }
}

@media (min-width: 1025px) {
  .quadriptych {
    margin-left: 64px;
  }

  .body {
    gap: 24px;
  }

  .body h2 {
    font-size: 1.5rem;
  }

  .body p {
    font-size: 1.125rem;
  }

  .link {
    font-size: 1.5rem;
  }
}
