.root {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.root::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-grey-60);
  border-radius: 50%;
}

.container {
  position: absolute;
  top: 0;
  display: grid;
  grid-template-columns: 0.54fr 0.46fr;
  gap: 8px;
  width: 100%;
  height: 100%;
  padding: 16% 2%;
}

.container img {
  border-radius: 8px;
  object-fit: contain;
}

.container img:nth-of-type(1) {
  width: 94%;
  justify-self: flex-end;
}

.container img:nth-of-type(2) {
  width: 94%;
  align-self: flex-end;
}

.container img:nth-of-type(3) {
  width: 100%;
}

.container img:nth-of-type(4) {
  width: 100%;
}

@media (min-width: 1025px) {
  .container {
    gap: 16px;
  }
}
